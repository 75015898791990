<template>
  <div class="no-data-container">
    <div class="content-container">
      <div class="text-container">
        {{ noDataText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoDataComponent',
  props: {
    noDataText: {
      type: String,
      default: 'No Data Avaliable For The Time Being'
    }
  }
}
</script>
<style lang="less" scoped>
@size__small-circle: 24px;
@size__large-circle: @size__small-circle * 1.8;
.no-data-container {
  position: absolute;
  left: 0;
  top: @height__table-header;
  height: @height__table-td;
  width: 100%;
  .center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .content-container {
    .center();
    width: 330px;
    height: 40px;
    .text-container {
      text-align: right;
      font-size: 1.8rem;
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: fade(@color-text__content, 20%);
      z-index: 1;
      white-space: nowrap;
    }
  }
}
</style>
