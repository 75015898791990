import request from '@/utils/request'

export function getPdiDamageDetail(data) {
  return request({
    url: 'business/v1/service//history/list',
    method: 'post',
    data
  })
}
export function getPdiDetail(params) {
  return request({
    url: 'business/v1/service//history/pdi/detail',
    method: 'get',
    params
  })
}
export function getDamageDetail(params) {
  return request({
    url: 'business/v1/service//history/damage/detail',
    method: 'get',
    params
  })
}
