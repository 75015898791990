import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import setting from '@/settings'
import { autoLoadModule } from './util'

Vue.use(Vuex)
const modules = autoLoadModule()
const store = new Vuex.Store({
  strict: setting.store.strict,
  modules,
  getters
})

export default store
