import moment from 'moment'

/**
 * init the calendar, generate the each day in current month
 * @param {boolean} half half period or not
 * @param {Date | string | Moment} month
 */
export const generateCalendar = (half = false, month = Date.now()) => {
  month = moment(month)
  if (!month.isValid) {
    throw new Error('invalid month date!')
  }
  const startDay = 1
  // 行数显示判断
  let rowCount
  const startSat = moment(month.date(1)).date(startDay)
  if (Number(month.daysInMonth()) === 30 && startSat._d.getDay() === 6) {
    rowCount = 6
  } else if (Number(month.daysInMonth()) === 31 && (startSat._d.getDay() === 6 || startSat._d.getDay() === 5)) {
    rowCount = 6
  } else {
    rowCount = 5
  }
  const rowLength = half ? 3 : rowCount
  const cellLength = rowLength * 7
  const days = new Array(cellLength)
  for (let i = 0; i < Number(month.daysInMonth()); i++) {
    const tempDate = moment(month.date(1)).date(startDay + i)
    days[i + month.date(startDay).day()] = {
      date: tempDate,
      display: tempDate.format('D'),
      isWeekend: tempDate.format('d') === '0' || tempDate.format('d') === '6',
      isCurrent: tempDate.isSame(Date.now(), 'day'),
      period: tempDate.date() <= 15 ? 1 : 2,
      active: false
    }
    if (startDay + i === month.daysInMonth()) break
  }
  return days
}

/**
 * 获取UUID
 */
export function getUUID(len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  let i
  radix = radix || chars.length

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
  } else {
    // rfc4122, version 4 form
    let r
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data. At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }

  return uuid.join('')
}

/**
 * 格式化数字，用逗号分隔
 * @param para 数字
 * @return 经过处理的新字符串
 */
export function toThousands(para) {
  var str = (para || 0).toString()
  var num = str.indexOf('.') > 0 ? str.substr(0, str.indexOf('.')) : str
  var tail = str.indexOf('.') > 0 ? str.substr(str.indexOf('.'), str.length) : ''
  var result = ''
  while (num.length > 3) {
    result = ',' + num.slice(-3) + result
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result + tail
  }
  return result
}

/**
  * @des 获取当前设备类型，并录入store
  * @return 当前设备类型
  */
export function getDeviceType() {
  const userAgent = navigator.userAgent.toLowerCase()
  const isIpad = userAgent.indexOf('ipad') > -1
  const isMobilePhone = userAgent.indexOf('ipad') < 0 && userAgent.indexOf('mobile') > -1
  if (isMobilePhone) {
    return 'mobile'
  } else if (isIpad) {
    // console.log('pc')
    // store.commit('SET_DEVICETYPE', 'pc')
  }
}
