const autoLoadModule = () => {
  // https://webpack.js.org/guides/dependency-management/#requirecontext
  const modulesFiles = require.context('./modules', true, /\.js$/)

  // you do not need `import app from './modules/app'`
  // it will auto require all vuex module from modules file
  return modulesFiles.keys().reduce((modules, modulePath) => {
    // set './app.js' => 'app' or './mySelf/index.js' => 'mySelf'
    // only scan one layer, './mySelf/modules/xxx.js' can not auto require
    // const moduleName = modulePath.replace(/^\.\/(.*)(\.|\\)\w+$/, '$1')
    let moduleName = modulePath
    const addModules = (reg) => {
      moduleName = modulePath.match(reg)[1]
      const value = modulesFiles(modulePath)
      modules[moduleName] = value.default
    }
    const folderReg = /^\.\/(\w+)\/index\.\w+$/
    const fileReg = /^\.\/(\w+)\.\w+$/
    folderReg.test(modulePath) ? addModules(folderReg) : fileReg.test(modulePath) ? addModules(fileReg) : modules
    return modules
  }, {})
}

/**
 * 创建store module 对象
 * @param config
 * @returns {{mutation: *, getter: *}}
 */
function createModule(config) {
  const { state, mutations, getters } = config
  const init = { ...state }
  const stateMutation = (() => {
    const _mutation = {}
    for (var key in state) {
      _mutation['SET_' + (key || '').toUpperCase()] = (_state, val) => {
        if (state[key] instanceof Array) {
          _state[key] = [...val]
        } else {
          _state[key] = { ..._state[key], ...val }
        }
      }
    }
    return { ..._mutation, ...mutations }
  })()
  stateMutation['SET_INIT'] = (_state) => {
    _state = init
  }
  const stateGetter = (() => {
    const _getter = {}
    for (var key in state) {
      _getter[key] = state => state[key]
    }
    return { ..._getter, ...getters }
  })()
  return { ...config, ...{ mutations: stateMutation, getters: stateGetter }}
}

export { autoLoadModule, createModule }

